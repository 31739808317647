<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card color="#385F73" dark>
          <v-card-title class="text-h5">
            Product Categories
          </v-card-title>
        </v-card>
      </v-col>

      <v-col cols="12">
        <router-view />
        <h1>Select page to edit</h1>
      </v-col>

      <v-col xs="12" sm="6" md="3">
        <v-card color="primary" dark>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5" v-text="'Products'"></v-card-title>

              <v-card-actions>
                <v-btn
                  class="ml-2 my-2"
                  :to="`/pages/categories/main`"
                  outlined
                  rounded
                  small
                >
                  Edit
                </v-btn>
                <v-btn
                  class="ml-2 my-2"
                  :href="`https://zgcc-eu.com/${getLanguage.lng}/products/`"
                  target="_blank"
                  color="green"
                  outlined
                  rounded
                  small
                >
                  preview
                </v-btn>
              </v-card-actions>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col v-for="(item, i) in content" :key="i" xs="12" sm="6" md="3">
        <v-card color="primary" dark>
          <div class="d-flex flex-no-wrap justify-space-between">
            <div>
              <v-card-title class="text-h5" v-text="item.name"></v-card-title>

              <v-card-actions>
                <v-btn
                  class="ml-2 my-2"
                  :to="
                    `/pages/categories/${item.category.slug}/${item.categoryId}`
                  "
                  outlined
                  rounded
                  small
                >
                  Edit
                </v-btn>
                <v-btn
                  class="ml-2 my-2"
                  :href="`https://zgcc-eu.com/${getLanguage.lng}/products/${item.category.slug}`"
                  target="_blank"
                  color="green"
                  outlined
                  rounded
                  small
                >
                  preview
                </v-btn>
              </v-card-actions>
            </div>
            <v-avatar class="ma-3" size="70" tile>
              <v-img
                :src="
                  `${baseUrl}thumbnails/${item.category.coverImage.thumbnail}`
                "
              ></v-img>
            </v-avatar>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "@/config";

export default {
  data: () => ({
    content: {}
  }),

  computed: {
    ...mapGetters({
      getLanguage: "auth/defaultLanguage"
    }),

    baseUrl() {
      return config.mediaBaseUrl;
    }
  },

  methods: {
    ...mapActions({
      delete: "category/deleteOne",
      getAllCategories: "category/getAllCategories"
    }),

    async loadData() {
      const { data } = await this.getAllCategories({
        order: "desc",
        perPage: 10,
        page: 1,
        lng: this.getLanguage.lng
      });

      this.content = data;
    },

    stringToColour(str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      var colour = "#";
      for (var i = 0; i < 3; i++) {
        var value = (hash >> 2) & 0xff;
        colour += ("00" + value).substr(-2);
      }
      return colour;
    }
  },

  mounted() {
    this.loadData();
  }
};
</script>
